import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncoderService {

  constructor() { }

  public getArraytoEncode(ArrayToEncode) {

    let ArrToEncode = ArrayToEncode.join('');
    return encodeURIComponent(ArrToEncode);
  }

  public encodeSubject(Subject) {

    return encodeURIComponent(Subject);
  }


}
