<div class="container">
  <div class="py-5 text-center">
    <h2>Formular</h2>
  </div>

  <div class="row">
    <div class="col">
      <div class="row col">
        <div>
          <p class="helpTextInsideStandard">
            Bitte dieses Formular ausfüllen, wenn wir ein neues Hostingpaket
            anlegen sollen.
          </p>
        </div>
      </div>

      <div class="mb-3">
        <label>Mein Name:</label>
        <input [(ngModel)]="MeinName" class="form-control" />
      </div>
      <div class="mb-3">
        <label>Meine Telefonnummer:</label>
        <input [(ngModel)]="MeineTelefonnummer" class="form-control" />
      </div>

      <div class="mb-3">
        <label>Domain, unter der die Webseite dann live sein soll:</label>
        <input [(ngModel)]="DomainName" class="form-control" />
      </div>
      <div class="col mb-3">
        <select
          [(ngModel)]="DomainDropdownA"
          class="custom-select d-block w-100"
        >
          <option>Wir können diese Domain bereits jetzt verwenden</option>
          <option>
            Das System muss vorerst unter einer Sandbox-Domain erreichbar sein
          </option>
        </select>
      </div>
      <div class="col mb-3">
        <select
          [(ngModel)]="DomainDropdownB"
          class="custom-select d-block w-100"
        >
          <option>
            Bitte ein leeres Wordpress-System installieren und Zugangsdaten
            zusenden
          </option>
          <option>
            Die Inhalte sollen von einem anderen System übernommen werden
          </option>
        </select>
      </div>
      <div class="row abstand">
        <button
          mat-flat-button
          class="col"
          (click)="sendMail()"
          class="speichernButton"
        >
          Mail generieren
        </button>
      </div>
    </div>
  </div>
</div>
