import { Component, OnInit } from '@angular/core';
import { EncoderService } from './services/encoder.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  MeinName: string;
  MeineTelefonnummer: string;
  DomainDropdownA: string;
  DomainDropdownB: string;
  DomainName: any;
  title = 'Formular';

  constructor(public encodeService: EncoderService) {}

  public ngOnInit() {}

  async sendMail() {
    alert('Mail wird geöffnet');

    let ArrayToEncode = [
      'Hallo,',
      '\n\n',
      'bitte ein neues Hosting-Paket anlegen.',
      '\n\n',
      'Domain, unter der die Webseite dann live sein soll:',
      '\n',
      this.DomainName,
      '\n\n',
      this.DomainDropdownA,
      '\n\n',
      this.DomainDropdownB,
      '\n\n',
      'Bei der Bereitstellung des Hosting-Pakets bitte auf folgende Merkmale achten:',
      '\n\n',
      'PHP-Version:  7.4',
      '\n\n',
      'Folgende php.ini-Einstellungen sind gesetzt (mindestens, gerne auch größer):',
      '\n',
      'max_input_vars: 3000',
      '\n',
      'max_execution_time: 300',
      '\n\n',
      'Folgende PHP-Module sind installiert:',
      '\n',
      'imagick',
      '\n\n',
      'Viele Grüße',
      '\n',
      this.MeinName,
      '\n',
      `Tel.: ${this.MeineTelefonnummer}`,
    ];

    window.location.href = `mailto:?subject=${this.encodeService.encodeSubject(
      `Hostingpaket anlegen ${this.MeinName}`
    )}&body=${this.encodeService.getArraytoEncode(ArrayToEncode)}`;
  }
}
